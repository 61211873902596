import Home from "../pages/Home";
import About from "../pages/About";
import Tariff from "../pages/Tariff";
import Cities from "../pages/Cities";
import Drivers from "../pages/Drivers";
import Feedback from "../pages/Feedback";
import Contacts from "../pages/Contacts";

export const HOME_ROUTE = '/'
export const ABOUT_ROUTE = '/about'
export const CONTACTS_ROUTE = '/contacts'
export const TARIFF_ROUTE = '/tariff'
export const DRIVERS_ROUTE = '/drivers'
export const FEEDBACK_ROUTE = '/feedback'
export const CITIES_ROUTE = '/cities'

export const NAVIGATION = {
    "home": <Home/>,
    "about": <About/>,
    "contacts": <Contacts/>,
    "tariff": <Tariff/>,
    "drivers": <Drivers/>,
    "feedback": <Feedback/>,
    "cities": <Cities/>
}

export const BUCHA_ROUTE = '/bucha'
export const KYIV_ROUTE = '/kyiv'
export const IRPIN_ROUTE = '/irpin'
export const TREBUHIV_ROUTE = '/trebuhiv'
export const BORYSPIL_ROUTE = '/boryspil'
export const BROVARY_ROUTE = '/brovary'
export const BOYARKA_ROUTE = '/boyarka'
export const AERO_ROUTE = '/airport-Boryspil'
export const UKRAINKA_ROUTE = '/ukrainka'
export const OBYKHIV_ROUTE = '/obykhiv'
export const VELIKAV_ROUTE = '/velika-vilshanka'
export const IVANCOVICHI_ROUTE = '/ivancovichi'
export const GLEVAHA_ROUTE = '/glevaha'
export const CALINIVKA_ROUTE = '/kalinivka'
export const VASILKIV_ROUTE = '/vasilkiv'
export const FASTIV_ROUTE = '/fastiv'
export const KAGARLYK_ROUTE = '/kagarlyk'
export const MAKARIV_ROUTE = '/makariv'
export const CITIES = [
    {
        name: 'kyiv'
    },
    {
        name: 'vasilkiv'
    },
    {
        name: 'boryspil'
    },
    {
        name: 'brovary'
    },
    {
        name: 'boyarka'
    },
    {
        name: 'obykhiv'
    },
    {
        name: 'ukrainka'
    },
    {
        name: 'glevaha'
    },
    {
        name: 'irpin'
    },
    {
        name: 'bucha'
    },
    {
        name: 'fastiv'
    },
    {
        name: 'kagarlyk'
    },
    {
        name: 'makariv'
    },
    {
        name: 'ovruch'
    }
]
export const LOCATION_MAPPING = {
    "STL1NQ7EW": "vasilkiv",
    "STL1NQ7EP": "kyiv",
    "STL1NQ7F9": "boryspil",
    "STL1NQ7F5": "brovary",
    "STL1NQ7EY": "boyarka",
    "STL1NQ7F2": "obykhiv",
    "STL1NQ7F3": "ukrainka",
    "STL1NQ7FT": "glevaha",
    "STL1NQ7EX": "irpin",
    "STL1NQ7FX": "bucha",
    "STL1NQ7EQ": "fastiv",
    "STL1NQ7F6": "kagarlyk",
    "STL1NQ7FH": "makariv",
    "STL1NN7DY": "ovruch"
}
