import React from 'react';
import classes from '../classes/Contacs.module.css'


const Loading = () => {
  return (
    <div className={classes.bgImgVas}>
      <div className={classes.positionCenter}>
        <div className="d-flex justify-content-center align-items-center mt-5">
            <h2 className='mt-4'>Завантаження...</h2>
        </div>
      </div>
    </div>
  );
};

export default Loading;
