import classes from '../classes/Home.module.css'
import google from '../img/google_play.webp'
import appStore from '../img/app_store.webp'
import vodafone from '../img/vodafone_icon.png'
import life from '../img/lifecell_logo.webp'
import kyivstar from '../img/kyivstar_icon.webp'
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useLocalStorage} from "usehooks-ts";


const Home = () => {
  const {t} = useTranslation()
  const {lang} = useParams();
  const [value, setValue, removeValue] = useLocalStorage('banner-opened', false);
  const [bannerClosed, setBannerClosed] = useLocalStorage('banner-closed', false);
  return (
      <div className={value && !bannerClosed ? classes.homeContainerBlur:classes.homeContainer}>
        <div style={{position: "relative"}}>
          <div className={classes.bgImage}></div>
          <div className={classes.bgText}>
            <h1 className={classes.textColor}>{t("name_company")}</h1>
            <h2>{t('desc_company')}</h2>
          </div>

          <div className={classes.contacts}>
            <div style={{position: "relative"}}>
              <div className={classes.list}>
                <a href='tel: 0932237070' className={classes.linkS}><p
                  className='contact-single-phone flex'>
                  <img className='d-inline-block'
                       width='30px'
                       height='30px'
                       src={life}
                       alt='logo'
                  />
                  <span style={{color: 'yellow'}}>093 223 70 70</span>
                </p></a>
                <a href='tel: 0982237070' className={classes.linkS}><p
                  className='contact-single-phone flex'>
                  <img className='d-inline-block'
                       width='30px'
                       height='30px'
                       src={kyivstar}
                       alt='logo'/>
                  <span style={{color: 'aqua'}}>098 223 70 70</span>
                </p></a>
                <a href='tel: 0952237070' className={classes.linkS}><p
                  className='contact-single-phone flex'>
                  <img className='d-inline-block'
                       width='30px'
                       height='30px'
                       src={vodafone}
                       alt='logo'/>
                  <span style={{color: 'red'}}>095 223 70 70</span>
                </p></a>
              </div>
            </div>
          </div>
          <div className={classes.imgGoogle}>
            <p>{t('home_google')}:</p>
            <a href='https://play.google.com/store/apps/details?id=ua.taxisimka' rel='nofollow'>
              <img
                src={google}
                alt="google play link"/>
            </a>
            <a
              href='https://apps.apple.com/us/app/%D1%82%D0%B0%D0%BA%D1%81%D1%96-%D1%81%D1%96%D0%BC%D0%BA%D0%B0-%D0%B2%D0%B0%D1%81%D0%B8%D0%BB%D1%8C%D0%BA%D1%96%D0%B2/id6446292238'
              rel='nofollow'>
              <img
                src={appStore}
                alt="app store link"/>
            </a>
          </div>

          <div className={classes.buttonsToRedirect}>
            <a href={`/drivers${lang === 'ru' ? '/ru' : ''}`}>
              <button>{t('nav_vakans')} </button>
            </a>
            <a href={`/tariff${lang === 'ru' ? '/ru' : ''}`}>
              <button>{t('home_class')}</button>
            </a>
          </div>
        </div>
      </div>
  );
};

export default Home;
