import {Navigate, Routes, useParams} from "react-router-dom";
import {Route} from 'react-router';

import Loading from "../pages/Loading";
import {Suspense, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom"
import {
    LOCATION_MAPPING,
    NAVIGATION
} from "../utils/consts";
import i18n from "../i18n";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {t} from "i18next";
import REGION from "../pages/cities/REGION";
import {useLocalStorage} from "usehooks-ts";

const MyHelmet = (props) => {
    const {navigation} = useParams();
    const {lang} = useParams();
    const navigate = useNavigate();
    const [value, setValue, removeValue] = useLocalStorage('geo-redirected', false);
    if (lang) {
        i18n.changeLanguage(lang);
    }
    let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };
    function success(pos) {
        let crd = pos.coords;
        let getCityUrl = `https://api.visicom.ua/data-api/5.0/en/geocode.json?categories=adr_address&n=${crd.longitude},${crd.latitude}&key=2e94667137470b70a7f972925942b776`
        fetch(getCityUrl)
            .then((response) => response.json())
            .then((data) => {
                const cityId = data.features[0].properties.settlement_id;
                console.log(LOCATION_MAPPING[cityId]);
                if(LOCATION_MAPPING[cityId]) {
                    navigate("/" + LOCATION_MAPPING[cityId]);
                    setValue(true);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
        console.log("Your current position is:");
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude: ${crd.longitude}`);
        console.log(`More or less ${crd.accuracy} meters.`);
    }

    function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    useEffect(() => {
        if (navigator.geolocation && window.location.pathname === "/home" && !value) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    console.log(result);
                    if (result.state === "granted") {
                        //If granted then you can directly call your function here
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "prompt") {
                        //If prompt then the user will be asked to give permission
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "denied") {
                        //If denied then you have to show instructions to enable location
                    }
                });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, []);
    const element = NAVIGATION[navigation] ? NAVIGATION[navigation] : <REGION/>;
    return (
        <HelmetProvider>
            <Helmet>
                <title>{t('title_' + navigation)}</title>
                <meta name="description" content={t('title_' + navigation) + ' - ' + t('title_description_region')}/>
            </Helmet>
            {element}
        </HelmetProvider>
    )
}


const AppRouter = () => {
    return (
        <Routes>
            <Route path="/:navigation"
                   element={
                       <Suspense fallback={<Loading/>}>
                           <MyHelmet/>
                       </Suspense>
                   }/>
            <Route path="/:navigation/:lang"
                   element={
                       <Suspense fallback={<Loading/>}>
                           <MyHelmet>
                           </MyHelmet>
                       </Suspense>
                   }/>
            <Route path='*' element={<Navigate to='/home'/>}/>
        </Routes>
    )
}

export default AppRouter;























