import React from 'react';
import classes from '../classes/Tariff.module.css'
import driver_img from "../img/car.webp"
import universal_img from "../img/taxi_universal.webp"
import miniven_img from "../img/taxi_miniven.webp"
import standart_img from "../img/standart.webp"
import delivery_img from "../img/delivery.webp"
import tech_img from "../img/tech2.webp"

import {useTranslation} from "react-i18next";
import {useLocalStorage} from "usehooks-ts";


const Tariff = () => {

  const {t} = useTranslation()
  const [value, setValue, removeValue] = useLocalStorage('banner-opened', false);
  const [bannerClosed, setBannerClosed] = useLocalStorage('banner-closed', false);

  return (
    <div className={value && !bannerClosed?classes.bodyBgBlur:classes.bodyBg}>
      <div className="container border-dark">
        <h1 className='text-center fw-bold'>{t('tariff_name')}</h1>
        <div className={classes.boxChoise}>
          <div className={classes.buttons}>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className={classes.blockS}><img
                  src={standart_img}
                  width="180"
                  height="120"
                  alt="Car Стандарт"/>
                  <div>
                    <span>Стандарт</span>
                    <p>{t('tariff_standart_desc')}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className={classes.blockS}><img
                  src={universal_img}
                  width="220"
                  height="120"
                  alt="Car Стандарт"/>
                  <div>
                    <span>{t('tariff_universal')}</span>
                    <p>{t('tariff_universal_desc')}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className={classes.blockS}><img
                  src={miniven_img}
                  width="220"
                  height="120"
                  alt="Car Стандарт"/>
                  <div>
                    <span>{t('tariff_miniven')}</span>
                    <p>{t('tariff_miniven_desc')}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className={classes.blockS}><img
                  className={classes.imageDriver}
                  src={driver_img}
                  width="220"
                  height="150"
                  alt="Car Стандарт"/>
                  <div>
                    <span>Драйвер</span>
                    <p>{t('tariff_driver_desc')}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className={classes.blockS}><img
                  src={delivery_img}
                  width="200"
                  height="140"
                  alt="Car Стандарт"/>
                  <div>
                    <span>Доставка</span>
                    <p>{t('tariff_delivery_desc')}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className={classes.blockS}><img
                  src={tech_img}
                  width="160"
                  height="160"
                  alt="Car Стандарт"
                  style={{marginTop: '10px', marginBottom: "-10px"}}
                />
                  <div>
                    <span>{t('tariff_tech')}</span>
                    <p>{t('tar_js_desc_tech')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='pb-5 fw-bold fs-2'>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tariff;
