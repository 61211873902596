import ua from './trans/ua.json'
import ru from './trans/ru.json'

import i18n from "i18next"
import {initReactI18next} from "react-i18next";

const resources = {
    ua: {
        translation: ua
    },
    ru: {
        translation: ru
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng:'ua',
        fallbackLng: 'ua',
    })

export default i18n;
