import classes from '../classes/Cities.module.css'
import {useTranslation} from "react-i18next";
import {CITIES} from "../utils/consts";
import {useParams} from "react-router-dom";

const Contacts = () => {
    const {lang} = useParams();

    const {t} = useTranslation()
    return (
        <div className={classes.bgImgVas}>
            <div className='container'>
                <div className={classes.text}>
                    <h2>{t('city2')}</h2>
                </div>
                <div className={classes.buttons}>
                    <div className='row text-center'>
                        {CITIES.map(function (object, i) {
                            return <div key={i} className="col-6 col-md-4 my-md-2">
                                <a href={`/${object.name}${lang === 'ru' ? '/ru' : ''}`}>
                                    <button>{t('city_' + object.name)}</button>
                                </a>
                            </div>;
                        })}
                    </div>
                </div>

            </div>
        </div>

    );
};

export default Contacts;
