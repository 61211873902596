import standartCar from '../img/class_standard.webp'
import universalCar from '../img/class_universal.webp'
import deliveryCar from '../img/class_express.webp'
import driverCar from '../img/class_driver.webp'



export const standart = {
  img: standartCar,
}

export const universal = {
  img: universalCar,
}

export const driver = {
  img: driverCar,
}

export const delivery = {
  img: deliveryCar,
}
